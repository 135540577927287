<template>
  <div class="container-enhancer flex">
    <div class="header flex justify-between items-center">
      <back-btn @back="handleBack" />
    </div>
    <div class="flex justify-center items-start" :style="{ marginTop: minClient ? '24px' : '49px' }">
      <!-- Before -->
      <div class="enhancer-item" style="margin-right: 60px;">
        <h2 :style="{ marginBottom: minClient ? '10px' : '24px' }"> Original </h2>
        <image-board :width="currentContainerSize.width">
          <video :src="props.videoInfo.url" v-if="props.mode === 'upload'" controls
            controlslist="nodownload  noplaybackrate" disablePictureInPicture />
          <video :src="demoMp4" v-else controls controlslist="nodownload  noplaybackrate" disablePictureInPicture muted />
        </image-board>
      </div>
      <!-- After -->
      <div class="enhancer-item">
        <h2 :style="{ marginBottom: minClient ? '10px' : '24px' }">Result preview</h2>
        <div class="position-box" @mouseenter="handleMouseEnter" @mouseleave="handleMouseLeave">
          <image-board :width="currentContainerSize.width" v-if="props.mode === 'upload'">
            <video :src="props.videoInfo.url" v-if="!previewUrl || !videoStartLoad" class="loading-first-frame"></video>
            <div v-if="isLoading || !previewUrl || !videoStartLoad" class="loading-mask"></div>
            <video v-if="!isLoading" :style="{ opacity: videoStartLoad ? 1 : 0 }" :src="previewUrl" controls
              controlslist="nodownload  noplaybackrate" disablePictureInPicture autoplay muted id="result-video"
              preload="metadata" />
          </image-board>
          <image-board :width="currentContainerSize.width" v-else>
            <video :src="resultMp4" controls controlslist="nodownload  noplaybackrate" disablePictureInPicture autoplay
              preload="metadata" muted id="result-video" />
          </image-board>
          <div class="buttons-container" v-if="showBtns">
            <a-button type="primary" @click.stop="handleDownload" :loading="downloadLoading" class="download-button">
              <div>
                <div>Download HD</div>
              </div>
            </a-button>
          </div>
        </div>
        <div class="common-rate-abs">
          <common-rate @changeRate="handleMattingChangeRate" />
        </div>
      </div>
    </div>
    <Modal :visible="showProessModal" :width="448" @cancel="handleCancel">
      <div class="modal-video-loading">
        <div class="modal-header">
          <img :src="success_icon" alt="success">
          <h1>Video processing </h1>
        </div>
        <p>It will take approximately {{ expectDuration(props.videoInfo?.duration) }} to process your full video. We
          will
          send you a notification when the process is completed.</p>
        <div class="modal-footer">
          <button class="modal-footer-button" @click.stop="handleCheckNotification">Check notification</button>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script setup>
import { ref, defineProps, defineEmits, onMounted, onUnmounted, watch, computed, nextTick } from "vue";
import BackBtn from "@/components/boolv-ui/back-btn";
import ImageBoard from "@/components/boolv-ui/image-board";
import CommonRate from "@/components/common-rate.vue";
import { Modal } from '@/components/boolv-ui/index.js'
import { eventTracking } from "@/utils/eventTracking.js";
import { getPreImage, generateVideoTask, getVideoEnhancer } from '@/api/models/video-enhancer.js'
import success_icon from './assets/success_icon.svg'
import { uploadVideoSingle } from "@/api/upload/upload.js";

import {
  MessageSuccess,
  MessageError,
  MessageLoading,
  destroy
} from "@/components/boolv-ui/Message/index.js";
import { downloadVideoFromUrl } from "@/utils";
import { downloadVideo, expectDuration } from './config.js'
import demoMp4 from './assets/video/output360.mp4'
import resultMp4 from './assets/video/result.mp4'
import { loadImage, authenticator } from '@/utils/index'
import { storeToRefs } from 'pinia'
import { useUserStore, useSideStore } from '@/store/index.js'

const user = useUserStore();
const { showNotification, updateNotification } = storeToRefs(user);
const sideStore = useSideStore()
const { minClient } = storeToRefs(sideStore)
const taskTimer = ref(null)
const videoStartLoad = ref(false)
const downloadUrl = ref('')
const isVideoCanplay = ref(false)
const isClickDownload = ref(false)
const props = defineProps({
  videoInfo: Object,
  mode: String,
  videoDuration: Number
})

const currentContainerSize = computed(() => {
  if (minClient) {
    return {
      width: '522px',
      height: '522px'
    }
  } else {
    return {
      width: '560px',
      height: '560px'
    }
  }
})

const emits = defineEmits(["back"]);
const downloadId = ref("");
const isLoading = ref(false);
const videoEnhancerTaskId = ref(null)
const previewUrl = ref('')
// const customControls = ref("nodownload disablePictureInPicture noplaybackrate")
onMounted(() => {
  if (props.mode == 'upload') {
    uploadVideo(props.videoInfo.file)
  }
});

const showProessModal = ref(false)
const uploadVideo = async (file) => {
  isLoading.value = true;
  sideStore.showGlobalMask = true;
  MessageLoading("Processing, it will take a while", 0,);
  if (!file) return

  try {
    const { code, data } = await uploadVideoSingle({ video: file });
    if (code === 0) {
      await getVideoPreImage(data.url)
    } else {
      destroy()
      MessageError("upload error")
    }
  } catch (err) {
    destroy()
    MessageError("upload error")
    console.log(err)
  }
}

const videoEnhancerComplete = (type, tip) => {
  isLoading.value = false
  sideStore.showGlobalMask = false;
  destroy()

  if (type) {
    switch (type) {
      case 'error':
        // 消息提示常驻
        MessageError(tip || 'Processing error, try agin', 10000000000)
        break
      case 'success':
        MessageSuccess(tip || 'Processing completed')
        break
      default:
        break
    }
  } else {
    MessageSuccess(tip || "Processing completed");
  }
}

const getPreViewVideoUrl = async (taskId) => {

  try {
    const { data } = await getVideoEnhancer({ taskId })
    const { outputUrl, success } = data

    if (!success || !data) {
      // 失败
      clearSetInterval()
      videoEnhancerComplete('error')
    }

    // 还没结束，继续
    if (!data.outputUrl) {
      return
    }

    //视频预览链接
    previewUrl.value = outputUrl
    if (props.videoDuration <= 5) {
      // 小于5秒的视频直接处理完成,存储用于后续下载
      downloadUrl.value = outputUrl

    }

    clearSetInterval()
    isLoading.value = false
    await nextTick()
    const resultVideo = document.getElementById('result-video')
    resultVideo.oncanplay = () => {
      // 视频加载完成才消失弹窗

      videoStartLoad.value = true
      if (!isVideoCanplay.value) {
        videoEnhancerComplete()
      }
      isVideoCanplay.value = true

    }

  } catch (_) {
    videoEnhancerComplete('error')
    clearSetInterval()
  }
}

const pollTask = () => {
  taskTimer.value = setInterval(() => {
    getPreViewVideoUrl(videoEnhancerTaskId.value)
  }, 3000)
}

const clearSetInterval = () => {
  clearInterval(taskTimer.value)
  taskTimer.value = null
}

watch(videoEnhancerTaskId, () => {
  if (!videoEnhancerTaskId.value) {
    return
  }
  pollTask()
})

const getVideoPreImage = async (url) => {
  try {
    const { code, data } = await getPreImage({ videoInputUri: url })
    if (code === 0) {
      const { taskId, previewId } = data;
      videoEnhancerTaskId.value = taskId
      downloadId.value = previewId

    } else {
      videoEnhancerComplete('error')
    }
  } catch (error) {
    videoEnhancerComplete('error')
  }
}


const handleBack = () => {
  emits("back");
};

const showBtns = ref(false);

const handleMouseEnter = () => {
  if (isLoading.value) {
    return;
  }
  showBtns.value = true;
};

const handleMouseLeave = () => {
  showBtns.value = false;
};

const handleMattingChangeRate = (item) => {
  const emoji = ['star_struck', 'face_blowing_a_kiss', 'thinking_face', 'face_vomiting'];
  eventTracking('booltool_page_feedback', { tool_name: 'video_enhancer', emoji_str: emoji[item.id - 1] });
};

const downloadFlag = ref(false);
const downloadLoading = ref(false);
//download video

const downloadMinVideo = () => {
  downloadLoading.value = false;
  downloadVideoFromUrl(downloadUrl.value);
  MessageSuccess("Download completed");
  eventTracking('booltool_page_download', { tool_name: 'video_enhancer', is_batch: false, is_success: true });
  destroy();
  authenticator.refresh();
}


const downloadExampleVideo = () => {
  downloadLoading.value = false;
  eventTracking('booltool_page_download', { tool_name: 'video_enhancer', is_batch: false, is_success: true });
  downloadVideo("result-video")
  MessageSuccess("Download completed");
  destroy();
  authenticator.refresh();
}

const handleDownload = async () => {
  downloadLoading.value = true;
  destroy();
  MessageLoading("Processing, it will take a while", 0, "", null, false);
  if (props.videoDuration <= 5) {
    downloadMinVideo()
    // 调一次生成接口完成扣费
    if (!isClickDownload.value) {
      generateVideoTask({ previewId: downloadId.value })
      isClickDownload.value = true
    }

    return
  }

  if (props.mode == 'example') {
    downloadExampleVideo()
    return
  }
  // 大于5s视频，直接弹消息提示窗
  if (downloadFlag.value) {
    destroy();
    // 点击过下载，再次点击下载，直接弹出消息提示框
    showNotification.value = true;
    downloadLoading.value = false;
    return
  }
  if (!downloadId.value) {
    return
  }

  try {
    await generateVideoTask({ previewId: downloadId.value })
    downloadFlag.value = true;
    // 未处理完成，异步处理，弹框提示
    showProessModal.value = true;
    updateNotification.value++;
    downloadLoading.value = false;
  } catch (_) {
    MessageError("download error");
  }
  destroy();
  authenticator.refresh();
  downloadLoading.value = false;
}

const handleCancel = () => {
  showProessModal.value = false
}

const handleCheckNotification = () => {
  showProessModal.value = false
  showNotification.value = true;
}

onUnmounted(() => {
  destroy()
})

</script>

<style lang="less" scoped>
.container-enhancer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 16px 36px 58px;
}

.header {
  height: 42px;
}

.enhancer-item {
  position: relative;

  h2 {
    padding-bottom: 24px;
    font-size: 21px;
    line-height: 26px;
    font-weight: 500;
  }
}

.common-rate-abs {}

.loading-mask {
  position: absolute;
  top: 0px;
  width: 100%;
  height: 100%;
  background: #f7f7f7;
  opacity: 0.5;
  pointer-events: all;
  z-index: 9;
}

.loading-first-frame {
  position: absolute;
  top: 0px;
  width: 100%;
  height: 100%;
  background: #f7f7f7;
  opacity: 0.5;
  pointer-events: all;
  z-index: 8;
}

:global(.download-button.ant-btn-primary) {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 180px !important;
  height: 56px !important;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.img-icon {
  cursor: pointer;
  width: 42px;
  height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.45);
  border-radius: 50%;

  &:hover {
    background-color: rgba(0, 0, 0, 0.65);

  }

  img {
    position: relative;
    width: 10px;
    height: 16px;
  }
}

.previous-icon {
  position: absolute;
  left: 24px;
  top: 50%;
  margin-top: -21px;
}

.next-icon {
  position: absolute;
  right: 24px;
  top: 50%;
  margin-top: -21px;
}

.current-img-box {
  position: absolute;
  bottom: 24px;
  left: 50%;
  margin-left: -20px;
  display: flex;
  width: 40px;
  justify-content: space-around;

  .current {
    width: 8px;
    height: 8px;
    border-radius: 50%;
  }
}

.buttons-container {
  position: absolute;
  bottom: 56px
}

.modal-video-loading {
  padding: 24px;

  .modal-header {
    display: flex;

    h1 {
      margin-left: 16px;
      font-weight: 500;
      font-size: 16px;
    }
  }

  p {
    padding: 10px 24px 24px 40px;
  }

  .modal-footer {
    text-align: right;

    .modal-footer-button {
      background: linear-gradient(289deg, #632CFF 8.11%, #8E68FF 99.95%);
      border-radius: 153px;
      padding: 5px 10px;
      font-weight: 400;
      font-size: 14px;
      color: #FFFFFF;
    }

    .modal-footer-button:hover {
      background: linear-gradient(289deg, #6f46f4 5.38%, #957aec 99.95%);

    }

  }
}
</style>
