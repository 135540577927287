//视频高清化
import { _post } from "@/utils/request.js";
const preApi =
  process.env.NODE_ENV !== "production" ? "/api" : process.env.VUE_APP_API;
const URL = {
  pre: preApi + "/ai/video_enhancer/pre",
  generate: preApi + "/ai/video_enhancer/generate",
  videoEnhancer: preApi + '/task/task/video_enhancer'
};

//预览
const getPreImage = async (data) => _post(URL.pre, data);

//generate
const generateVideoTask = async (data) => _post(URL.generate, data);

const getVideoEnhancer = async (data) => _post(URL.videoEnhancer, data);

export { getPreImage, generateVideoTask, getVideoEnhancer };
