<template>
  <div>
    <video-enhancer v-if="uploadComplete" :videoInfo="uploadVideoInfo" @back="handleBack" :mode="mode"
      :videoDuration="videoDuration"></video-enhancer>
    <video-upload @uploadComplete="handleUploadComplete" @videoDuration="getVideoDuration" v-else></video-upload>
  </div>
</template>

<script setup>
import videoEnhancer from './video-enhancer.vue'
import videoUpload from './video-upload.vue'
import { ref } from "vue";
import { eventTracking } from "@/utils/eventTracking.js";
import { getMaterialResolution } from "@/utils/index.js"
import {
  MessageError,
  destroy,
} from "@/components/boolv-ui/Message/index.js";


const uploadComplete = ref(false);
const uploadVideoInfo = ref({});
const mode = ref("");
const videoDuration = ref(0)
const exceedMaxPx = ref(false)

const getMaterialResolutionCallback = (width, height) => {
  //   视频高清化比例限制，必须同时满足：
  // 要求视频的宽度和高度都小于等于 1080
  //   width <= 1080 && height <= 1080
  // 宽高比大于等于 0.41
  //   width / height >= 0.41
  if (width <= 1080 && height <= 1080 && width / height >= 0.41) {
    exceedMaxPx.value = false
  } else {
    destroy()
    MessageError('Max 1080p × 1080p resolution')
    exceedMaxPx.value = true
    return
  }
}
const handleUploadComplete = async (m, info) => {
  mode.value = m
  eventTracking("booltool_page_upload", { tool_name: "video_enhancer", is_batch: false })
  if (m == 'upload') {
    const { width, height } = await getMaterialResolution(info.file, getMaterialResolutionCallback)
    getMaterialResolutionCallback(width, height)
  }

  if (!exceedMaxPx.value) {
    uploadComplete.value = true;
    uploadVideoInfo.value = info;
    console.log(uploadVideoInfo.value, '[upload]');
  }
};

const getVideoDuration = (value) => {
  videoDuration.value = value
}

const handleBack = () => {
  uploadVideoInfo.value = {};
  uploadComplete.value = false;
};
</script>

<style lang="less" scoped></style>