function downloadVideo (id) {
  // 获取 video 元素
  const videoElement = document.getElementById(id);
  if (!videoElement) {
    console.error("未找到 video 元素");
    return;
  }

  // 获取视频 URL
  const videoUrl = videoElement.src;
  if (!videoUrl) {
    console.error("未找到视频 URL");
    return;
  }
  console.log("[ videoUrl ] >", videoUrl);
  // 创建下载链接
  const downloadLink = document.createElement("a");
  downloadLink.href = videoUrl;
  downloadLink.download = videoUrl.split("/").pop();

  // 点击下载链接，开始下载
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
}

const expectDuration = (duration) => {
  return Math.ceil(duration * 2 / 60) + 'min'
  // return secondConvert(
  //   Math.ceil(naiveDuration(duration) / getConcurrency(duration))
  // );
};

const naiveDuration = (duration) => {
  return duration * 30 * 30;
};

const getConcurrency = (duration) => {
  if (duration <= 300) {
    return 10;
  } else if (duration <= 1200) {
    return 20;
  } else if (duration <= 2400) {
    return 30;
  } else {
    return Math.min(50, Math.ceil(duration / 85));
  }
};

const secondConvert = (second) => {
  //单位:s
  if (second < 3600) {
    return Math.round(second / 60) + "min";
  } else if (second >= 3600) {
    return Math.round(second / 3600) + "hour";
  }
};
export { downloadVideo, expectDuration };
