<template>
  <div class="common-upload-container flex justify-center items-center">
    <div class="upload-position-container">
      <div class="upload-box upload-single flex justify-center items-center" v-authenticate:BOOLTOOL_VIDEO_DURATION>
        <commonUpload :fileList="fileList" :setFileList="setFileList" placeHolder="Upload a video to start"
          :multiple="false" :customRequest="customRequest" :limitNum="1"
          creditTip="Max 500MB, 1min, 1080p × 1080p resolution" :iconUrl="iconUploadSingle"
          :videoConfig="videoLimitConfig" :iconStyle="{
            width: '46px',
            height: '58px',
            'margin-bottom': '25px',
          }" accept=".mp4,.mov" />
      </div>
      <div class="image-box-background">
        <div class="inner-image-box">
          <p class="tooltip-title">View sample video</p>
          <div class="example-images-box">
            <div class="example-image-icon-box" v-for="item in videoList" :key="item.id"
              v-authenticate:BOOLTOOL_VIDEO_DURATION="{ ok: () => handleSelectImage(item) }">
              <div class="mask-item"></div>
              <video :src="item.iconSrc" class="example-image-icon" autoplay muted loop> </video>
              <!-- <img :src="item.iconSrc" class="example-image-icon" /> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="disabled-mask">

    </div>
  </div>
</template>
<script setup>
import { ref, defineEmits } from "vue";
import commonUpload from "@/components/custom-upload/index.vue";
import { getVideoInfoFromFile, authenticator } from "@/utils/index.js";
import iconUploadSingle from "./assets/upload_icon_pro.svg";
import demoMp4 from './assets/video/output360.mp4'
import demo from "./assets/demo.webp";
import {
  MessageError,
} from "@/components/boolv-ui/Message/index.js";

import { useSubscriptionInfo } from '@/store/index.js'
import { modalManager } from '@/components/boolv-ui/global-modal/reactive.js';
const subscriptionInfo = useSubscriptionInfo();
const videoDuringResource = subscriptionInfo.getUserResource('BOOLTOOL_VIDEO_DURATION');
const uploadFileList = ref([]);
const videoList = ref([
  {
    id: 1,
    iconSrc: demoMp4,
    src: demo,
  },
]);
const videoLimitConfig = {
  size: "500000000", //500M  单位按照字节算, mac按照1000单位换算的
  totalTime: '1min'
}

const emits = defineEmits(["uploadComplete", "setLoading"]);

const customRequest = async (file) => {
  const originUrl = URL.createObjectURL(file);
  const videoInfo = await getVideoInfoFromFile(file)
  videoInfo.url = originUrl
  videoInfo.file = file

  //check credits
  if (
    !(await authenticator.authenticate('BOOLTOOL_VIDEO_DURATION')) ||
    videoInfo.duration > videoDuringResource.num
  ) {
    //no credits modal 
    modalManager.applyTemplate('no_premission');
    return;
  }

  //limit duration
  if (Math.ceil(videoInfo.duration) > 60) {
    MessageError("Video length cannot exceed 1min")
    return
  }

  //limit size
  if (Math.ceil(videoInfo.file.size) > 500000000) {
    MessageError("Video size cannot exceed 500MB")
    return
  }

  emits("uploadComplete", 'upload', videoInfo);
  emits("videoDuration", videoInfo.duration);
};

const setFileList = async (fileList = []) => {
  const fileArray = fileList.map((file) => file.originFileObj);
  uploadFileList.value = fileArray;
};

const handleSelectImage = async () => {
  //开启示例模式
  emits("uploadComplete", "example");
};

</script>

<style lang="less" scoped>
.common-upload-container {
  min-height: calc(100vh - 60px);

  .tooltip-card {
    width: 394px;

    left: "280px";
  }

  :global(.ant-tooltip-content) {
    width: 394px;
    height: 100%;
  }

  :global(.ant-tooltip-inner) {
    width: 394px;
    height: 100%;
    color: #8f959e;
  }
}

.upload-box {
  width: 588px;
  height: 196px;
  background: #ffffff;
  border: 1px dashed #d0d0d0;
  border-radius: 6px;
  cursor: pointer;

  &:hover {
    background-color: #f8f5ff;
  }
}

.upload-single {
  margin-right: 34px;
}

.example-images-container {
  width: 394px;

  background-color: green;
}

.example-image-icon {
  height: 110px;
  border-radius: 4px;
}

.example-images-box {
  display: flex;
  justify-content: center;
  padding: 22px 20px;
}

.image-box-background {
  width: 588px;
  height: 230px;
  padding-top: 14px;
}

.inner-image-box {
  width: 588px;
  padding: 0 95px;
}

.upload-position-container {
  position: relative;
}

.example-image-icon-box {
  cursor: pointer;
  position: relative;
  background-color: #F6F6F6;
  width: 197.29px;
  height: 110px;
  border-radius: 4px
}

.mask-item {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  opacity: 0;
  background-color: #000;
  transition: all .3s;
  cursor: pointer;

  &:hover {
    opacity: 0.3;
  }
}

.disabled-mask {
  width: 100%;
  height: 100%;
  background: #fff;
  opacity: 0.3;
  position: fixed;
  left: 0;
  top: 0;
}
</style>
